export const DEFAULT_LANGUAGE = 'jp';
export const MAX_QUANTITY_ORDER_ITEM_IN_CART = 99;
export const MAX_QUANTITY_ORDER_BUFFET_ITEM_IN_CART = 8;
export const DECRYPT_PASSWORD = 'anrakutei1129';

//SLIPSTATUS - CHECK USER CHECKOUT
export const CHECK_SLIP_STATUS_INTERVAL = 60_000;

// CUSTOMER ID
export const MIN_NON_MEMBER_ID = 9000000001;

export const SEARCH_MENU_MENUBOOKID = '-1';
export const GENRE_TOP_MENU_ID = '0';
// ALL YOU CAN EAT
export const TH_INFO_EMPTY_INFO_CODE = '99';
export const TH_INTERVAL_TIME_CHECK_REMAIN_TIME = 60_000;

// RESPONSE CODES
export const RES_CODE_SUCCESS = '00'; // API response SUCCESS
export const RES_CODE_CREDENTIAL_ERROR = '01'; // API authentication failed
export const RES_CODE_PARAMETER_ERROR = '02'; // API Parameter error
export const RES_CODE_DB_CONNECTION_ERROR = '03'; // DB connection error
export const RES_CODE_DATA_READ_ERROR = '04'; // Data read error (when SELECT SQL fails)
export const RES_CODE_DATA_WRITE_ERROR = '05'; // Data write error (when UPDATE, INSERT, DELETE SQL fails)
export const RES_CODE_SERVER_CONNECTION_ERROR = '06'; // Connection error to store server
export const RES_CODE_OUT_OF_STOCK_ERROR = '20'; // Out of stock
export const RES_CODE_EXCEED_COUPON_LIMIT = '06251'; // exceed coupon limit
export const RES_CODE_UNAUTHORIZED = '99010'; // exceed coupon limit

// TAP ANIMATION
export const TAP_ANIMATION_TIME = 400;

// HTTP ERROR
export const ERROR_NOTIFICATION_CODES = [403, 404, 500, 503];

export const REQUIRED_SELECT_SUBMENUGROUP = '必須選択';

export const OPTIONAL_SELECT_SUBMENUGROUP = '任意選択';

// GENRE
export const GENRE_SEARCH_MENU_ID = '-1';
export const COURSECD_DEFAULT = 99;

//MENU LIST
export const GENRE_STICKY_NAME_HEIGHT_DEFAULT = 36;

// CATEGORY
export const CATEGORY_FREQUENTLY_ORDERED_MENU_ID = '-2';
export const CATEGORY_SEARCH_MENU_ID = '-1';
// TOP campaign
export const CAMPAIGN_BANNER_TRANSITION_TIME = 5000;
// ALL YOU CAN EAT PROPERTY
export const NO_SELECTION_PROPERTY = '選択なし';
export const ALL_YOU_CAN_EAT_PROPERTY = '食べ放題／飲み放題';

export const NO_SELECTION_PROPERTY_NUM = '1';
export const ALL_YOU_CAN_EAT_PROPERTY_NUM = '2';

export const FREQUENTLY_ORDERED_MENU_MODEID = '-2';
export const SEARCH_MENU_MODEID = '-1';
export const GRAND_MENU_MODEID = '1';
export const LUNCH_MENU_MODEID = '2';
export const ALL_YOU_CAN_EAT_MENU_MODEID = '3';
export const PITA_MODEID = '4';
// UPDATETIME - CHECK TIME INTERVAL
export const CHECK_TIME_INTERVAL = 60_000;
export const MAX_COUPON_PER_PERSON_PER_ITEM = 2;
