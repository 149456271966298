import { IMenu, IOrder, ISubMenuGroup, MenuInfoDataType } from 'apis/types';
import { CartStateData, PortionState, PortionStateItem } from 'models';
import { getDishInfo, initDishState } from 'utils';
import { getSubmenuGroupFromMenubookId } from 'utils/getSubmenuGroupFromMenubookId';

/**
 * Converts an array of order items into a cart state data object.
 *
 * @param orderList - An array of order items containing menu book code, menu item code, and quantity.
 * @param menuList - The complete list of available menu items.
 * @param subMenuGroupData - The sub-menu group information for the selected menu items.
 * @param menuInfo - The menu information containing the menu book and eating_way_id details.
 * @returns A cart state data object containing the state of each selected dish.
 */
export const convertOrderListToCartState = (
  orderList: IOrder[],
  menuList: IMenu,
  subMenuGroupData: ISubMenuGroup,
  menuInfo: MenuInfoDataType,
  customerQuantity: number
): CartStateData => {
  const cartState: CartStateData = {};

  orderList.forEach((orderItem) => {
    const menuBookCode = orderItem.modecd;
    const menuItemCode = orderItem.poscd;
    const submenuGroupInfo = getSubmenuGroupFromMenubookId(menuInfo, menuBookCode, menuItemCode);
    const menuBookCodeAndMenuItemCode = JSON.stringify([menuBookCode, menuItemCode]);
    const num = parseInt(orderItem.num);
    const dishInfo = getDishInfo(menuList, menuItemCode);

    if (dishInfo) {
      const initialState = initDishState(menuList, subMenuGroupData, submenuGroupInfo, customerQuantity, false)[0];
      const portionState = convertInitialStateToPortionState(menuList, subMenuGroupData, initialState, orderItem, num);

      const detailDishState = [...Array(num)].map(() => {
        return structuredClone(portionState);
      });

      if (menuBookCodeAndMenuItemCode in cartState) {
        detailDishState.forEach((detailDishStateItem) => {
          cartState[menuBookCodeAndMenuItemCode].state.push(detailDishStateItem);
        });
      } else {
        const dishState = {
          menuItemInfo: dishInfo || {},
          init: initialState,
          state: detailDishState,
        };

        cartState[menuBookCodeAndMenuItemCode] = dishState;
      }
    }
  });

  return cartState;
};

export const convertInitialStateToPortionState = (
  menuList: IMenu,
  subMenuGroup: ISubMenuGroup,
  initialState: PortionState,
  orderItem: IOrder,
  num: number
) => {
  const newDishState = structuredClone(initialState);
  const groupsSelected = orderItem.select[0].group;
  const processGroups: string[] = [];

  for (const group of groupsSelected) {
    const groupCode = group.gno;
    const subMenuGroupItem = subMenuGroup[groupCode];

    if (!newDishState[`${groupCode}-0`].detail) {
      continue;
    }

    const detailList = { ...newDishState[`${groupCode}-0`].detail };
    const selectedDishCode: string[] = [];

    const menu = group.menu;
    menu.forEach((option) => {
      const optionCode = option.poscd;
      const optionInfo = getDishInfo(menuList, optionCode);
      const existingOption = detailList[optionCode] || {};

      const initOptionState = {
        ...existingOption,
        optionInfo,
        checked: true,
        quantity: Math.ceil(parseInt(option.num) / num),
      };

      detailList[optionCode] = initOptionState;
      selectedDishCode.push(optionCode);

      const childGroupSelected = group.group;

      if (childGroupSelected && childGroupSelected.length > 0) {
        const listChildInforOptionGroups: PortionState = {
          ...newDishState[`${groupCode}-0`].detail[optionCode].childInfor,
        };

        const childProcessGroups: string[] = [];

        for (const childGroup of childGroupSelected) {
          const childGroupCode = childGroup.gno;

          if (!listChildInforOptionGroups[`${childGroupCode}-0`].detail) {
            continue;
          }

          const childSubMenuGroupItem = { ...listChildInforOptionGroups[`${childGroupCode}-0`].basic };
          const childDetailList = {
            ...listChildInforOptionGroups[`${childGroupCode}-0`].detail,
          };

          const childSelectedDishCode: string[] = [];
          const childMenu = childGroup.menu;

          childMenu.forEach((childOption) => {
            const childOptionCode = childOption.poscd;
            const childOptionInfo = getDishInfo(menuList, childOptionCode);
            const existingChildOption = childDetailList[childOptionCode] || {};

            const initChildOptionState = {
              ...existingChildOption,
              optionInfo: childOptionInfo,
              checked: true,
              quantity: Math.ceil(parseInt(childOption.num) / num),
            };

            childDetailList[childOptionCode] = initChildOptionState;
            childSelectedDishCode.push(childOptionCode);
          });

          const childOptionGroup = {
            basic: childSubMenuGroupItem,
            detail: childDetailList,
            selected: childSelectedDishCode,
          };

          listChildInforOptionGroups[`${childGroupCode}-0`] = childOptionGroup;
          childProcessGroups.push(childGroupCode);
        }

        initOptionState.childInfor = listChildInforOptionGroups;
      }
    });

    const optionGroup: PortionStateItem = {
      basic: subMenuGroupItem,
      detail: detailList,
      selected: selectedDishCode,
    };

    newDishState[`${groupCode}-0`] = optionGroup;
    processGroups.push(groupCode);
  }

  return newDishState;
};
